import {
  Typography,
  Stack,
  CircularProgress,
  Paper,
  Avatar,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
} from "@mui/material";
import { useMemo } from "react";
import { ClockComment } from "../../gitlabApi/getClockRecords";
import { productAll } from "../../hooks/useProducts";
import { ReportRecord } from "../../hooks/useReport";
import { useAppSelector } from "../../redux/store";

const ClockRecord: React.FC<{ record: ReportRecord; isMob: boolean }> = ({
  record,
  isMob,
}) => {
  const combineIssues = useAppSelector((s) => s.product.combineIssues);
  const { records, hasMileStone } = useMemo(() => {
    const hasMileStone = (combined: ClockComment[]) =>
      combined.findIndex((r) => !!r.issue.milestone?.title) >= 0;
    let res = [...record.records].sort(
      (a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()
    );
    if (combineIssues) {
      const combined: ClockComment[] = [];
      for (const record of res) {
        const mainRecord = combined.find(
          (c) =>
            c.issue.iid === record.issue.iid &&
            c.issue.project.id === record.issue.project.id
        );
        if (mainRecord) {
          mainRecord.id = mainRecord.issue.iid;
          mainRecord.link = mainRecord.issue.webUrl;
          mainRecord.spend += record.spend;
        } else
          combined.push({
            author: record.author,
            id: record.id,
            issue: record.issue,
            link: record.link,
            spend: record.spend,
            updatedAt: record.updatedAt,
          });
      }
      return { records: combined, hasMileStone: hasMileStone(combined) };
    }
    return { records: res, hasMileStone: hasMileStone(res) };
  }, [record.records, combineIssues]);
  return (
    <Paper sx={{ mt: 2 }}>
      <Stack
        direction="column"
        spacing={5}
        sx={{ alignItems: "center", padding: 2 }}
      >
        <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={1}>
          <Avatar
            component={"a"}
            href={record.author.url}
            target="_blank"
            rel="noopener noreferrer"
            src={record.author.avatar}
          />
          {!isMob && (
            <Typography variant="h6" color={"#9c27b0"}>
              {record.author.name}'s
            </Typography>
          )}
          <Typography variant="h6">Total:</Typography>
          <Typography variant="h6" color={"#9c27b0"}>
            {+record.total.toFixed(2)}
          </Typography>
          <Typography variant="h6"> hours</Typography>
          <Button
            color="secondary"
            onClick={() => navigator.clipboard.writeText(String(record.total))}
          >
            Copy
          </Button>
        </Stack>
        <Typography variant="h6">Work Log:</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Issue</TableCell>
                <TableCell>Project</TableCell>
                <TableCell align="right">Spend</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Time</TableCell>
                {hasMileStone && <TableCell align="right">Milestone</TableCell>}
                {combineIssues && <TableCell align="right">Estimate</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((row) => (
                <TableRow
                  key={`${row.issue.iid}-${row.id}-${row.issue.project.id}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <a
                      href={row.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row.issue.title}
                    </a>
                  </TableCell>
                  <TableCell align="left">
                    <a
                      href={row.issue.project.webUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row.issue.project.name}
                    </a>
                  </TableCell>
                  <TableCell align="right">{+row.spend.toFixed(2)}</TableCell>
                  <TableCell align="right">
                    {row.updatedAt.toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.updatedAt.toLocaleTimeString()}
                  </TableCell>
                  {hasMileStone && (
                    <TableCell align="right">
                      {row.issue.milestone?.title ?? ""}
                    </TableCell>
                  )}
                  {combineIssues && (
                    <TableCell align="right">
                      {row.issue.time_stats?.time_estimate
                        ? row.issue.time_stats.time_estimate / 60 / 60 + " h"
                        : "-"}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Paper>
  );
};
const safeDailyIssuesAll = 25;
const safeDailyIssuesProject = 10;
const safeMinIssuesProduct = 40;
const dangerPeriodDays = 14;

export const ClockContent: React.FC<{ isMob: boolean }> = ({ isMob }) => {
  const { report, product, maxIssues } = useAppSelector((s) => s.product);
  const { from, to } = useAppSelector((s) => s.range);
  const isSmallAmount = useMemo(() => {
    if (!from || !to || !product) return undefined;
    const days = (to.getTime() - from.getTime()) / 1000 / 60 / 60 / 24;
    if (days > dangerPeriodDays)
      return "Dangerously big period! Results can be incorrect";
    const danger =
      "Dangerously low amount of issues for period! Results can be incorrect";
    if (product.name === productAll) {
      const avgIssues = maxIssues / days;
      if (avgIssues <= safeDailyIssuesAll) return danger;
    } else {
      if (maxIssues >= safeMinIssuesProduct) return undefined;
      const avgIssues = maxIssues / days;
      if (avgIssues <= safeDailyIssuesProject) return danger;
    }
    return undefined;
  }, [from, to, product, maxIssues]);
  return (
    <>
      {report.error && <Alert severity="error">{report.error}</Alert>}
      {isSmallAmount && <Alert severity="warning">{isSmallAmount}</Alert>}
      {report.loading !== undefined && (
        <Stack
          direction="row"
          spacing={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography variant="h3">{report.loading}</Typography>
        </Stack>
      )}
      {report.data.map((record) => (
        <ClockRecord
          key={record.author.username}
          record={record}
          isMob={isMob}
        />
      ))}
    </>
  );
};
